import { AntDesign, FontAwesome, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo } from 'react';
import {
  FlatList,
  Pressable,
  StyleProp,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import CreativeConnectDark from '/assets/jsicons/CreativeConnectDark';
import Button from '/components/Button';
import ButtonToggle from '/components/ButtonToggle/ButtonToggle';
import CreativeConnectProjectCard from '/components/CreativeConnect/CreativeConnectProjectCard/CreativeConnectProjectCard';
import CreativeTypeIcon from '/components/CreativeConnect/CreativeTypeIcon/CreativeTypeIcon';
import ListLoading from '/components/ListLoading';
import WebScrollbarTheme from '/components/WebScrollbarTheme';
import GenericError from '/components/common/Generic/GenericError';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionContainer from '/components/common/Section/SectionContainer';
import SectionText from '/components/common/Section/SectionText';
import SectionTitle from '/components/common/Section/SectionTitle';
import SectionTitleIconContainer from '/components/common/Section/SectionTitleIconContainer';
import {
  BUTTON_LABEL_STYLE,
  CREATIVE_CONNECT_BACKGROUND_GRAY,
  KEY_GRAY,
  PRIMARY_BUTTON_BACKGROUND,
} from '/constants';
import { useAuthContext } from '/context';
import {
  CreativeConnectProjectCreativeType,
  CreativeConnectProjectFilter,
  ListCreativeConnectProjectsQuery,
  useListCreativeConnectProjectsQuery,
  UserRole,
} from '/generated/graphql';
import { base64EncodeObject, isOrganizationProfileComplete } from '/util';

export default function DiscoverCreativeConnect() {
  const { userData } = useAuthContext();
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const { styles } = useStyles();

  const [premieredProjects, refetchPremieredProjects] =
    useListCreativeConnectProjectsQuery({
      variables: {
        filter: {
          is_premiered: true,
        },
      },
    });
  const [openForCollabProjects, refetchOpenForCollabProjects] =
    useListCreativeConnectProjectsQuery({
      variables: {
        filter: {
          accepting_applications: true,
        },
      },
    });
  // const [inDevelopmentProjects, refetchInDevelopmentProjects] =
  //   useListCreativeConnectProjectsQuery({
  //     variables: {
  //       filter: {
  //         is_premiered: false,
  //         accepting_applications: false,
  //       },
  //     },
  //   });

  return premieredProjects.data?.listCreativeConnectProjects.items.length ||
    openForCollabProjects.data?.listCreativeConnectProjects.items.length ? (
    <SectionContainer
      style={{
        backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
      }}
    >
      <HorizontalContainer
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <HorizontalContainer style={{ flex: 1 }}>
          <SectionTitleIconContainer>
            {/* Creative Connect */}
            <CreativeConnectDark width={24} height={24} />
          </SectionTitleIconContainer>
          <SectionTitle
            style={{
              flex: 1,
              marginBottom: 0,
              color: 'white',
            }}
          >
            CREATIVE CONNECT
          </SectionTitle>
        </HorizontalContainer>

        {userData?.role === UserRole.Supporter ||
        (userData?.role === UserRole.Conservationist &&
          isOrganizationProfileComplete(userData!)) ? (
          <Button
            onPress={() => {
              navigate('CreateCreativeConnectProject');
            }}
            style={{
              backgroundColor: 'white',
            }}
            label={
              <HorizontalContainer>
                <AntDesign name="pluscircleo" size={15} color="black" />
                <Text style={BUTTON_LABEL_STYLE}>{` `}Create</Text>
              </HorizontalContainer>
            }
            containerStyle={{
              marginLeft: 8,
              alignSelf: 'flex-end',
            }}
          />
        ) : null}
      </HorizontalContainer>

      <View
        style={{
          marginTop: 10,
        }}
      >
        <SectionText style={styles('sectionText')}>
          Discover all kinds of creative productions about conservation, nature,
          and wildlife to learn, share knowledge, or simply appreciate the
          natural world.
        </SectionText>
        {!premieredProjects.data?.listCreativeConnectProjects.items.length ? (
          premieredProjects.fetching ? (
            <ListLoading color={'white'} />
          ) : premieredProjects.error ? (
            <GenericError
              onRetry={refetchPremieredProjects}
              message="Failed to load premiered projects"
            />
          ) : (
            <Text style={styles('emptyText')}>
              No relased projects at the moment. Check back later!
            </Text>
          )
        ) : (
          <Projects
            section="premiered"
            data={premieredProjects.data?.listCreativeConnectProjects.items}
          />
        )}

        {/* <SectionTitle style={styles('sectionTitle')}>IN DEVELOPMENT</SectionTitle>
      <SectionText style={styles('sectionText')}>
        These projects are still in development and have not been released yet.
      </SectionText> */}
        {/* {!inDevelopmentProjects.data?.listCreativeConnectProjects.items.length ? (
        inDevelopmentProjects.fetching ? (
          <ListLoading color={'white'} />
        ) : inDevelopmentProjects.error ? (
          <GenericError
            onRetry={refetchInDevelopmentProjects}
            message="Failed to load in-development projects"
          />
        ) : (
          <Text style={styles('emptyText')}>
            No in-development projects at the moment. Check back later!
          </Text>
        )
      ) : (
        <Projects
          section="in_development"
          data={inDevelopmentProjects.data?.listCreativeConnectProjects.items}
        />
      )} */}

        <SectionTitle style={styles('sectionTitle')}>
          OPEN FOR COLLABORATION
        </SectionTitle>
        <SectionText style={styles('sectionText')}>
          Apply to collaborate with creators on their projects.
        </SectionText>
        {!openForCollabProjects.data?.listCreativeConnectProjects.items
          .length ? (
          openForCollabProjects.fetching ? (
            <ListLoading color={'white'} />
          ) : openForCollabProjects.error ? (
            <GenericError
              onRetry={refetchOpenForCollabProjects}
              message="Failed to load open projects"
            />
          ) : (
            <Text style={styles('emptyText')}>
              No open projects at the moment. Check back later!
            </Text>
          )
        ) : (
          <Projects
            section="open_for_collab"
            style={{
              marginBottom: 8,
            }}
            data={openForCollabProjects.data?.listCreativeConnectProjects.items}
          />
        )}
      </View>
    </SectionContainer>
  ) : null;
}

const CategoryLabelMap: {
  [key in CreativeConnectProjectCreativeType]: string;
} = {
  [CreativeConnectProjectCreativeType.Film]: 'Film',
  [CreativeConnectProjectCreativeType.Photography]: 'Photography',
  [CreativeConnectProjectCreativeType.Art]: 'Art',
  [CreativeConnectProjectCreativeType.Game]: 'Game',
  [CreativeConnectProjectCreativeType.Podcast]: 'Podcast',
};

type ProjectsProps = {
  section: 'premiered' | 'in_development' | 'open_for_collab';
  style?: StyleProp<ViewStyle>;
  data: ListCreativeConnectProjectsQuery['listCreativeConnectProjects']['items'];
};

type RenderProjectCategory = {
  category: CreativeConnectProjectCreativeType | null;
  projects: ProjectsProps['data'];
};

function Projects(props: ProjectsProps) {
  const { styles } = useStyles();
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  function onViewCategory(category: CreativeConnectProjectCreativeType) {
    const params: CreativeConnectProjectFilter = {
      is_premiered: true,
      creative_types: [category],
    };

    navigate('ListCreativeConnectProjects', {
      ccf: base64EncodeObject(params),
    });
  }

  const categories = useMemo((): RenderProjectCategory[] => {
    if (props.section !== 'premiered') {
      /** Only one row for in-development and open for collaboration sections */
      return [
        {
          category: null,
          projects: props.data,
        },
      ];
    }

    return props.data.reduce((acc, cur) => {
      const category = cur.creative_type;

      if (!category) {
        return acc;
      }

      if (!acc.some((item) => item.category === category)) {
        acc.push({
          category,
          projects: [cur],
        });
        return acc;
      }

      const index = acc.findIndex((item) => item.category === category);

      acc[index].projects.push(cur);

      return acc;
    }, [] as RenderProjectCategory[]);
  }, [props.data, props.section]);

  const categoriesToRender = useMemo(() => {
    return categories.slice(0, 2);
  }, [categories]);

  return (
    <WebScrollbarTheme theme="dark" containerStyle={props.style}>
      {categoriesToRender.map((category, index) => {
        return (
          <View key={category.category}>
            {category.category ? (
              <HorizontalContainer
                style={{
                  justifyContent: 'space-between',
                  marginTop: index !== 0 ? 12 : 0,
                  marginBottom: 4,
                }}
              >
                <Pressable
                  onPress={() => {
                    category.category && onViewCategory(category.category);
                  }}
                  style={({ pressed }) => ({
                    opacity: pressed ? 0.6 : 1,
                  })}
                >
                  <ButtonToggle
                    style={{
                      marginRight: 12,
                      marginBottom: 0,
                    }}
                    onPress={() => {
                      if (!category.category) return;

                      onViewCategory(category.category);
                    }}
                    label={
                      <HorizontalContainer>
                        <CreativeTypeIcon
                          creativeType={category.category}
                          size={16}
                          color={KEY_GRAY}
                          style={{
                            marginRight: 4,
                          }}
                        />
                        <Text
                          style={{
                            fontFamily: 'Lato-Bold',
                            textAlign: 'center',
                            color: KEY_GRAY,
                            fontSize: 14,
                          }}
                        >
                          {CategoryLabelMap[category.category]}
                        </Text>
                      </HorizontalContainer>
                    }
                    disabled
                    selected
                  />
                </Pressable>

                <Pressable
                  style={({ pressed }) => ({
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignSelf: 'flex-end',
                    flexShrink: 1,
                    opacity: pressed ? 0.6 : 1,
                    marginBottom: 4,
                  })}
                  onPress={() => {
                    category.category && onViewCategory(category.category);
                  }}
                >
                  <Text style={styles('seeMoreInCategoryText')}>
                    See more in "{CategoryLabelMap[category.category]}"{' '}
                  </Text>
                  <FontAwesome
                    name="caret-right"
                    size={17}
                    color="white"
                    style={{
                      marginTop: 1,
                      marginLeft: 6,
                    }}
                  />
                </Pressable>
              </HorizontalContainer>
            ) : null}
            <FlatList
              horizontal
              data={category.projects}
              style={{
                marginBottom: 4,
              }}
              contentContainerStyle={styles('flatListContentContainer')}
              renderItem={({ item, index: cardIndex }) => {
                return (
                  <HorizontalContainer
                    style={[
                      {
                        marginLeft: cardIndex === 0 ? 0 : 8,
                        alignItems: 'flex-start',
                      },
                      props.section === 'open_for_collab' && cardIndex !== 0
                        ? {
                            paddingLeft: 8,
                            borderLeftWidth: 1,
                            borderLeftColor: 'lightgray',
                          }
                        : undefined,
                    ]}
                  >
                    <CreativeConnectProjectCard
                      style={styles('projectCard')}
                      data={item}
                      navigateShouldFocusOpenForCollaboration={
                        props.section === 'open_for_collab'
                      }
                      smallThumbnail={props.section === 'open_for_collab'}
                      displayCreativeTypeAndStatus={
                        props.section === 'open_for_collab' ||
                        (props.section === 'in_development'
                          ? 'creative-type-only'
                          : false)
                      }
                    />
                    {/* {category.category &&
                    index === category.projects.length - 1 &&
                    category.projects.length > category.projects.total ? (
                      <Pressable
                        style={({ pressed }) => [
                          styles('seeAllInCategory'),
                          {
                            opacity: pressed ? 0.5 : 1,
                          },
                        ]}
                        onPress={() => {
                          if (!category.category) return;

                          const params: CreativeConnectProjectFilter = {
                            is_premiered: true,
                            creative_types: [category.category],
                          };

                          navigate('ListCreativeConnectProjects', {
                            ccf: base64EncodeObject(params),
                          });
                        }}
                      >
                        <FontAwesome5
                          name="arrow-circle-right"
                          size={42}
                          color={'white'}
                        />
                        <Text style={styles('seeAllInCategoryText')}>
                          See All Projects in "
                          {CategoryLabelMap[category.category]}""
                        </Text>
                      </Pressable>
                    ) : null} */}
                  </HorizontalContainer>
                );
              }}
            />
          </View>
        );
      })}

      <View style={styles('bottomContainer')}>
        <Button
          containerStyle={styles('viewMoreBottomButton')}
          onPress={() => {
            const filter: CreativeConnectProjectFilter = {};

            switch (props.section) {
              case 'in_development':
                filter.is_premiered = false;
                break;
              case 'open_for_collab':
                filter.accepting_applications = true;
                break;
              case 'premiered':
                filter.is_premiered = true;
                break;
              default:
                props.section satisfies never;
            }

            navigate('ListCreativeConnectProjects', {
              ccf: base64EncodeObject(filter),
            });
          }}
          label={
            <HorizontalContainer>
              <Text style={BUTTON_LABEL_STYLE}>
                Browse{' '}
                {props.section === 'premiered'
                  ? 'Creative Connect'
                  : props.section === 'in_development'
                  ? 'In Development'
                  : 'Open for Collaboration'}
              </Text>
              {/* <FontAwesome5 name="angle-right" size={17} color="white" /> */}
              <Ionicons
                name="caret-forward-circle-outline"
                size={18}
                color="black"
                style={{
                  marginLeft: 4,
                }}
              />
            </HorizontalContainer>
          }
        />

        {props.section === 'premiered' ? (
          <View style={styles('allCategoriesSection')}>
            <SectionTitle
              style={{
                fontSize: 17,
                color: '#eee',
              }}
            >
              ALL CATEGORIES
            </SectionTitle>
            <View style={styles('moreCategoriesContainer')}>
              {(
                Object.entries(CategoryLabelMap) as [
                  CreativeConnectProjectCreativeType,
                  string,
                ][]
              ).map(([key, value]) => {
                return (
                  <Pressable
                    key={key}
                    style={({ pressed }) => ({
                      marginRight: 8,
                      opacity: pressed ? 0.5 : 1,
                    })}
                    onPress={() => {
                      onViewCategory(key);
                    }}
                  >
                    <ButtonToggle
                      style={{
                        marginRight: 0,
                      }}
                      label={
                        <HorizontalContainer>
                          <CreativeTypeIcon
                            creativeType={key}
                            size={16}
                            color={KEY_GRAY}
                            style={{
                              marginRight: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontFamily: 'Lato-Bold',
                              textAlign: 'center',
                              color: KEY_GRAY,
                              fontSize: 14,
                            }}
                          >
                            {value}
                          </Text>
                        </HorizontalContainer>
                      }
                      disabled
                      color={PRIMARY_BUTTON_BACKGROUND}
                    />
                  </Pressable>
                );
              })}
            </View>
          </View>
        ) : null}
      </View>
    </WebScrollbarTheme>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    emptyText: {
      fontFamily: 'Lato',
      color: 'gray',
      textAlign: 'center',
      alignSelf: 'center',
      padding: 12,
    },
    sectionTitle: {
      color: 'white',
      fontSize: 20,
      fontFamily: 'LeagueSpartan-Bold',
      letterSpacing: 0.3,
      marginTop: 24,
      marginBottom: 2,
    },
    sectionText: {
      color: 'white',
      marginBottom: 6,
    },
    projectCard: {
      width: 300,
    },
    seeMoreInCategoryText: {
      fontFamily: 'Lato-Bold',
      fontSize: 17,
      color: 'white',
      marginTop: -2,
      textAlign: 'right',
    },
    seeAllInCategory: {
      width: 160,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 12,
    },
    seeAllInCategoryText: {
      fontFamily: 'Lato-Bold',
      fontSize: 15,
      color: 'white',
      textAlign: 'center',
      paddingVertical: 8,
    },
    flatListContentContainer: {
      marginBottom: 10,
    },
    moreCategoriesContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    viewMoreBottomButton: {
      alignSelf: 'flex-end',
    },
    allCategoriesSection: {
      maxWidth: '55%',
    },
    bottomContainer: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      marginTop: 10,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      projectCard: {
        width: 240,
      },
      allCategoriesSection: {
        maxWidth: '100%',
        marginTop: 16,
      },
      bottomContainer: {
        flexDirection: 'column',
      },
      viewMoreBottomButton: {
        alignSelf: 'flex-end',
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      viewMoreBottomButton: {
        width: '100%',
      },
    },
  },
);
