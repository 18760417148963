import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent, View } from 'react-native';
import GenericError from '../common/Generic/GenericError';
import GenericListFooter from '../GenericListFooter';
import GridList from '../GridList';
import CampaignBlankSpace from '../Profile/CampaignBlankSpace';
import JobBoardFilter from './elements/JobBoardFilter';
import JobPostCard from './elements/JobPostCard';
import { KEY_GRAY } from '/constants';
import { JobPostFilter, useListJobPostsQuery } from '/generated/graphql';
import { isCloseToBottom } from '/util';
import ListLoading from '../ListLoading';
import ScrollView from '../common/ScrollView/ScrollView';
import Button from '../Button';

interface Props {
  query: string;
  initialFilter?: JobPostFilter;
  disablePagination?: boolean;
  hideFilters?: boolean;
  onFilterChanged?: (filter: JobPostFilter) => void;
}

export default function JobBoard({ onFilterChanged, ...props }: Props) {
  const navigation = useNavigation<StackNavigationProp<any>>();

  const [filter, _setFilter] = useState<JobPostFilter>({});
  function setFilter(newFilter: JobPostFilter) {
    _setFilter(newFilter);
    onFilterChanged?.(newFilter);
  }

  const [nextToken, setNextToken] = useState<string | null>(null);

  const [{ data, fetching, error, stale }, listJobs] = useListJobPostsQuery({
    variables: {
      filter: {
        ...filter,
        title: props.query,
        closed: false,
      },
      nextToken,
    },
  });

  const onScroll = useCallback(
    ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (props.disablePagination) return;

      if (isCloseToBottom(nativeEvent)) {
        setNextToken(data?.listJobPosts.nextToken ?? null);
      }
    },
    [data?.listJobPosts.nextToken, props.disablePagination],
  );

  function onSeeMorePressed() {
    navigation.navigate('JobBoard', {
      q: props.query,
      jf: JSON.stringify(filter),
    });
  }

  return (
    <ScrollView onScroll={onScroll}>
      {props.hideFilters ? null : (
        <JobBoardFilter filter={filter} setFilter={setFilter} />
      )}
      {data?.listJobPosts.total === 0 && !fetching ? (
        <CampaignBlankSpace placeholderText="No results" />
      ) : error ? (
        <GenericError onRetry={listJobs} />
      ) : null}
      <GridList
        key={JSON.stringify(props.query) + JSON.stringify(filter)}
        data={data?.listJobPosts.items}
        maxTileWidth={400}
        renderItem={({ item }) => {
          return (
            <View
              style={{
                flex: 1,
                borderWidth: 1,
                borderColor: KEY_GRAY,
                borderRadius: 6,
                padding: 8,
              }}
            >
              <JobPostCard
                key={item.id}
                data={item}
                onPress={() => {
                  navigation.navigate('ViewJobPost', {
                    id: item.id,
                  });
                }}
              />
            </View>
          );
        }}
      />
      {fetching && !data?.listJobPosts.total ? (
        <ListLoading />
      ) : props.disablePagination ? (
        data?.listJobPosts.nextToken ? (
          <Button
            style={{
              backgroundColor: KEY_GRAY,
            }}
            labelStyle={{
              color: 'white',
            }}
            containerStyle={{
              marginTop: 8,
            }}
            label="SEE MORE"
            onPress={onSeeMorePressed}
          />
        ) : null
      ) : (
        <GenericListFooter
          hasMore={!props.disablePagination && !!data?.listJobPosts.nextToken}
          onFetchMore={() => {
            if (!props.disablePagination)
              setNextToken(data?.listJobPosts.nextToken ?? null);
          }}
          emptyHeight={0}
          isFirstPage={!nextToken}
          loading={fetching || stale}
        />
      )}
    </ScrollView>
  );
}
