import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import EventDateFilterModal from './elements/EventDateFilterModal';
import EventTypeFilterModal from './elements/EventTypeFilterModal';
import EventLocationFilterModal from './elements/EventLocationFilterModal';
import FilterComponent from '/components/common/FilterComponent';
import { useModalContext } from '/context';
import { EventFilterInput, EventType } from '/generated/graphql';
import { eventTypesToLabels } from '/utils/eventTypeLabels';
import { StyleProp, ViewStyle } from 'react-native';

type Props = {
  filter?: EventFilterInput;
  onChange: (filter: EventFilterInput) => void;
  style?: StyleProp<ViewStyle>;
};

export default function EventFilter(props: Props) {
  const { spawnModal, closeModal } = useModalContext();

  const [locationFilterName, setLocationFilterName] = useState<string>();

  const filterModalIdRef = useRef<string>();

  function onFilterDate() {
    filterModalIdRef.current = spawnModal({
      title: 'Filter By Date',
      component: (
        <EventDateFilterModal
          initialFilter={props.filter}
          onRequestClose={(filter) => {
            if (filterModalIdRef.current) closeModal(filterModalIdRef.current);
            props.onChange({ ...props.filter, ...filter });
          }}
        />
      ),
    });
  }

  function onFilterType() {
    filterModalIdRef.current = spawnModal({
      title: 'Filter By Type',
      component: (
        <EventTypeFilterModal
          initialSelectedTypes={props.filter?.types || []}
          onRequestClose={(selectedTypes) => {
            if (filterModalIdRef.current) closeModal(filterModalIdRef.current);
            const payload: typeof props.filter = {
              ...props.filter,
              types: selectedTypes,
            };

            if (
              selectedTypes.length &&
              !selectedTypes.some((t) =>
                [EventType.Hybrid, EventType.InPerson].includes(t),
              )
            ) {
              payload.location = null;
            }

            props.onChange(payload);
          }}
        />
      ),
    });
  }

  function onFilterLocation() {
    filterModalIdRef.current = spawnModal({
      title: 'Filter By Location',
      style: {
        overflow: 'visible',
        maxWidth: 480,
      },
      component: (
        <EventLocationFilterModal
          initialLocation={
            props.filter?.location
              ? {
                  ...props.filter.location,
                  name: locationFilterName ?? '',
                }
              : null
          }
          onRequestClose={(location) => {
            if (filterModalIdRef.current) closeModal(filterModalIdRef.current);
            props.onChange({
              ...props.filter,
              location:
                typeof location?.latitude === 'number' &&
                typeof location?.longitude === 'number'
                  ? {
                      latitude: location.latitude,
                      longitude: location.longitude,
                      radius: location.radius ?? 50,
                    }
                  : undefined,
            });
            setLocationFilterName(location?.name);
          }}
        />
      ),
    });
  }

  const dateFilterLabel = (() => {
    if (props.filter?.minStartDate && props.filter?.maxStartDate) {
      return `${dayjs(props.filter.minStartDate).format(
        'MMM D, YYYY',
      )} - ${dayjs(props.filter.maxStartDate).format('MMM D, YYYY')}`;
    }

    if (props.filter?.minStartDate) {
      return `After ${dayjs(props.filter.minStartDate).format('MMM D, YYYY')}`;
    }

    if (props.filter?.maxStartDate) {
      return `Before ${dayjs(props.filter.maxStartDate).format('MMM D, YYYY')}`;
    }

    return 'Any';
  })();

  const typeFilterLabel = (() => {
    if (props.filter?.types && props.filter.types.length > 0) {
      return eventTypesToLabels(props.filter.types);
    }
    return 'Any';
  })();

  const locationFilterLabel = (() => {
    if (props.filter?.location) {
      return `${locationFilterName ?? 'Co-ordinates'} (${
        props.filter.location.radius || 50
      }km)`;
    }
    return 'Any';
  })();

  return (
    <FilterComponent.Container style={props.style}>
      <FilterComponent.Filter
        label="Date"
        type="button"
        onPress={onFilterDate}
        buttonLabel={dateFilterLabel}
      />
      <FilterComponent.Filter
        label="Type"
        type="button"
        onPress={onFilterType}
        buttonLabel={typeFilterLabel}
      />
      {props.filter?.types?.length &&
      props.filter?.types.every(
        (t) => ![EventType.Hybrid, EventType.InPerson].includes(t),
      ) ? null : (
        <FilterComponent.Filter
          label="Location"
          type="button"
          onPress={onFilterLocation}
          buttonLabel={locationFilterLabel}
        />
      )}
    </FilterComponent.Container>
  );
}
