import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import dayjs from 'dayjs';
import React from 'react';
import {
  Image,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import Avatar from '../Avatar';
import LikeButtonComponent from '../LikeButtonComponent';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import { KEY_GRAY } from '/constants';
import {
  NewsPost,
  PostInteractionsFragmentFragment,
  User,
  useAddLikeMutation,
  useCreateNewsPostBookmarkMutation,
  useDeleteNewsPostBookmarkMutation,
} from '/generated/graphql';
import CommentsButton from '../CampaignPost/elements/CommentsButton';
import ShareButton from '../Sharing/ShareButton';
import { createUniversalURL } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';
import { useAuthContext } from '/context';

interface INewsPost
  extends Pick<
    NewsPost,
    | 'id'
    | 'thumbnail'
    | 'created_at'
    | 'headline'
    | 'is_bookmarked'
    | 'is_good_news'
  > {
  user: Pick<User, 'name' | 'profile_image'> | null | undefined;
  interactions: PostInteractionsFragmentFragment;
}

type Props = {
  data: INewsPost;
  style?: StyleProp<ViewStyle>;
  hideNewsHeader?: boolean;
  onPress?: () => void;
};

export default function NewsPostCard({
  data,
  onPress,
  hideNewsHeader,
  ...props
}: Props) {
  const { userData } = useAuthContext();

  const [, addLike] = useAddLikeMutation();

  const [, addBookmark] = useCreateNewsPostBookmarkMutation();
  const [, deleteBookmark] = useDeleteNewsPostBookmarkMutation();

  return (
    <Pressable onPress={onPress} style={[styles.container, props.style]}>
      <View style={styles.contentContainer}>
        <View style={styles.textContainer}>
          {!hideNewsHeader ? <Text style={styles.newsHeader}>News</Text> : null}
          <Text style={styles.headline} numberOfLines={4}>
            {data.headline}
          </Text>
          <HorizontalContainer style={styles.authorContainer}>
            <Avatar
              source={{
                uri: data.user?.profile_image ?? '',
              }}
              rounded
              size={20}
              containerStyle={styles.avatarContainer}
            />
            <Text style={styles.userName}>{data.user?.name}</Text>
          </HorizontalContainer>
        </View>

        <View style={styles.thumbnailContainer}>
          {data.thumbnail ? (
            <Image
              source={{
                uri: getCDNImageUri({
                  uri: data.thumbnail,
                  isThumbnail: true,
                  dimensions: {
                    width: 100,
                    height: 100,
                  },
                }),
              }}
              style={styles.thumbnail}
              resizeMode="cover"
            />
          ) : null}
          {data.is_good_news ? (
            <FontAwesome5
              name="key"
              size={18}
              color={data.thumbnail ? 'white' : 'black'}
              style={styles.keyIcon}
            />
          ) : null}
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Text style={styles.timestamp}>
          {dayjs(Number(data.created_at)).fromNow()}
        </Text>

        <HorizontalContainer>
          <LikeButtonComponent
            isLiked={data.interactions.is_liked}
            returnToOnLogIn={`/news/${data.id}`}
            likeCount={data.interactions.likes}
            size={36}
            style={{
              backgroundColor: 'transparent',
            }}
            onPress={() => {
              addLike({
                interactionsId: data.interactions.id,
              });
            }}
          />

          <CommentsButton
            commentCount={data.interactions.comments.total}
            interactionsId={data.interactions.id}
            style={{
              backgroundColor: 'transparent',
            }}
            size={36}
          />

          <ShareButton
            shareMessage="Check out this news article on Key Conservation!"
            url={createUniversalURL(`news/${data.id}`)}
            style={{
              backgroundColor: 'transparent',
              width: 36,
              height: 36,
            }}
            iconSize={24}
          />

          {/* Only show bookmark button if signed in */}
          {userData?.id ? (
            <View
              style={{
                width: 36,
                height: 36,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {data.is_bookmarked ? (
                <FontAwesome
                  name="bookmark"
                  size={24}
                  color="black"
                  onPress={() => {
                    deleteBookmark({ id: data.id });
                  }}
                /> // solid
              ) : (
                <FontAwesome
                  name="bookmark-o"
                  size={24}
                  color="black"
                  onPress={() => addBookmark({ id: data.id })}
                />
              )}
            </View>
          ) : null}
        </HorizontalContainer>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 150,
    justifyContent: 'space-between',
    padding: 8,
  },
  contentContainer: {
    flexDirection: 'row',
  },
  textContainer: {
    flex: 1,
    marginRight: 8,
  },
  newsHeader: {
    fontFamily: 'Lato-BoldItalic',
    fontSize: 16,
    color: 'gray',
    marginBottom: 2,
  },
  headline: {
    color: KEY_GRAY,
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    textTransform: 'capitalize',
  },
  authorContainer: {
    marginVertical: 4,
  },
  avatarContainer: {
    marginRight: 4,
  },
  userName: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 14,
    marginTop: 4,
    textTransform: 'uppercase',
    color: KEY_GRAY,
  },
  thumbnailContainer: {
    minWidth: 24,
    minHeight: 24,
  },
  thumbnail: {
    width: 100,
    height: 100,
    borderRadius: 6,
  },
  keyIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  timestamp: {
    fontFamily: 'Lato-Italic',
    color: 'gray',
  },
});
