import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  GetNotificationsForUserDocument,
  ListEventAttendeesFragmentFragmentDoc,
  NotificationType,
} from '/generated/graphql';
import { isValidJson } from '/util';
import removeItemFromPaginatedField from '/util/cache/removeItemFromPaginatedField';
import { gql } from 'urql';

const EventAttendeeFragment = gql`
  fragment DeleteEventAttendeeFragment on Event {
    id
    attendee(userId: $userId) {
      id
      userId
      eventId
    }
  }
`;

const GetDeletedEventAttendeeFragment = gql`
  fragment GetDeletedEventAttendeeFragment on EventAttendee {
    id
    eventId
    userId
  }
`;

const deleteEventAttendee: UpdateResolver = (result, args, cache) => {
  const eventAttendee = cache.readFragment(GetDeletedEventAttendeeFragment, {
    __typename: 'EventAttendee',
    id: args.attendeeId as string,
  });

  removeItemFromPaginatedField({
    cache,
    entity: {
      __typename: 'Event',
      id: eventAttendee.eventId as string,
    },
    paginatedField: {
      name: 'attendees',
      fragment: ListEventAttendeesFragmentFragmentDoc,
    },
    removeItem: {
      id: args.attendeeId as string,
    },
  });

  // Go through all notifications, remove those whose `data` field contains the strings
  // `eventId: ${eventId}` and `userId: ${userId}`.
  const queries = cache.inspectFields('Query');
  const getNotificationsQueries = queries.filter(
    (q) => q.fieldName === 'getNotificationsForUser',
  );

  getNotificationsQueries.forEach((q) => {
    cache.updateQuery(
      {
        query: GetNotificationsForUserDocument,
        variables: q.arguments,
      },
      (data) => {
        const prev = data?.getNotificationsForUser as any;
        return {
          ...data,
          __typename: 'Query',
          getNotificationsForUser: {
            __typename: 'GetAllNotificationsResponse',
            ...prev,
            actionable: ((prev?.actionable as any[]) ?? []).reduce(
              (acc, cur) => {
                const section = {
                  ...cur,
                  notifications: cur.notifications.filter((n: any) => {
                    if (n.type !== NotificationType.EventAttendanceInvite)
                      return true;

                    const parsedData = isValidJson(n.data)
                      ? JSON.parse(n.data)
                      : {};

                    return parsedData?.eventAttendeeId !== args.attendeeId;
                  }),
                };

                if (section.notifications.length === 0) return acc;
                return [...acc, section];
              },
              [],
            ),
          },
        };
      },
    );
  });

  const ARGUMENT_SETS_TUPLE = [
    undefined,
    { userId: eventAttendee?.userId },
  ] as const;

  ARGUMENT_SETS_TUPLE.forEach((_args) => {
    const event = cache.readFragment(
      EventAttendeeFragment,
      {
        __typename: 'Event',
        id: eventAttendee?.eventId as string,
      },
      _args,
    );

    if (event?.attendee?.userId === eventAttendee?.userId) {
      cache.link(
        {
          __typename: 'Event',
          id: eventAttendee?.eventId as string,
        },
        'attendee',
        _args ?? null,
        _args ? null : undefined,
      );
    }
  });
};

export default deleteEventAttendee;
