import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useClient } from 'urql';
import { AppTimeDocument } from '/generated/graphql';

// Store the original Date class
const OriginalDate = Date;

let globalTimeDifference = 0;

class ServerAdjustedDate extends OriginalDate {
  constructor(...args: any[]) {
    if (args.length === 0) {
      super(OriginalDate.now() + globalTimeDifference);
    } else {
      // @ts-ignore
      super(...args);
    }
  }

  static now(): number {
    return OriginalDate.now() + globalTimeDifference;
  }
}

type ServerTimeContext = {
  serverTime: Date | null;
  timeDifference: number;
  getServerAdjustedDate: () => Date;
  getOriginalDate: () => typeof OriginalDate;
};

const ctx = createContext<ServerTimeContext>({
  serverTime: null,
  timeDifference: 0,
  getServerAdjustedDate: () => new Date(),
  getOriginalDate: () => OriginalDate,
});

export function useServerTime() {
  return useContext(ctx);
}

export default function ServerTimeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [serverTime, setServerTime] = useState<Date | null>(null);
  const [timeDifference, setTimeDifference] = useState<number>(0);

  const client = useClient();
  const clientRef = useRef(client);
  clientRef.current = client;

  useEffect(() => {
    const fetchServerTime = async () => {
      try {
        const startTime = OriginalDate.now();
        const response = await clientRef.current
          .query(AppTimeDocument, {}, { requestPolicy: 'network-only' })
          .toPromise();
        const endTime = OriginalDate.now();
        const roundTripTime = endTime - startTime;

        const serverTimeString = response?.data?.appTime;
        const serverTimeDate = new OriginalDate(serverTimeString);

        const adjustedServerTime = new OriginalDate(
          serverTimeDate.getTime() + roundTripTime / 2,
        );

        setServerTime(adjustedServerTime);
        globalTimeDifference =
          adjustedServerTime.getTime() - OriginalDate.now();
        setTimeDifference(globalTimeDifference);

        // Override the global Date object
        (global as any).Date = ServerAdjustedDate;
      } catch (error) {
        console.error('Failed to fetch server time:', error);
      }
    };

    fetchServerTime();

    const intervalId = setInterval(fetchServerTime, 60000 * 15); // Sync every 15 minutes

    return () => {
      clearInterval(intervalId);
      // Restore original Date object on unmount
      (global as any).Date = OriginalDate;
    };
  }, []);

  const getServerAdjustedDate = () => new Date();
  const getOriginalDate = () => OriginalDate;

  return (
    <ctx.Provider
      value={{
        serverTime,
        timeDifference,
        getServerAdjustedDate,
        getOriginalDate,
      }}
    >
      {children}
    </ctx.Provider>
  );
}
