import {
  NavigationRoute,
  StackNavigationState,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

type NavigationTools = {
  /**
   * Removes all matching routes from current stack and navigates
   * to the last route in the resulting stack */
  invalidateRoutes: (routes: { routeName: string; params?: any }[]) => void;
  /**
   * Finds the current path in the navigation state given state
   */
  findCurrentPath: (state: StackNavigationState<any>) => string | null;
};

export default function useNavigationTools(): NavigationTools {
  const { reset, getState } = useNavigation<StackNavigationProp<any>>();

  return {
    invalidateRoutes: (filterRoutes) => {
      const newState = { ...getState() };

      newState.routes = newState.routes.filter((r) => {
        return !filterRoutes.some(
          (f) =>
            f.routeName === r.name &&
            Object.entries(f.params ?? {}).every(
              ([key, value]) => r.params?.[key] === value,
            ),
        );
      });

      newState.index = newState.routes.length - 1;

      reset(newState);
    },
    findCurrentPath: (state: StackNavigationState<any>): string | null => {
      const huntRoute = (
        _route: Partial<NavigationRoute<any, any>>,
      ): string | null => {
        // Check if index exists
        const { state: _state, path } = _route;

        const index = _state?.index || 0;

        // If index is a number, recurse into routes[index]
        if (_state?.routes.length && _state.routes[index]) {
          return huntRoute(_state.routes[index]);
        }

        // If index is not a number, return the path if it exists
        return path || null;
      };

      const index = state.index || 0;

      if (typeof state.routes.length && state.routes[index]) {
        return huntRoute(state.routes[index]);
      }

      // Return null if no path is found
      return null;
    },
  };
}
