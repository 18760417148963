import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { RouteProp, useScrollToTop } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useLocales } from 'expo-localization';
import _, { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Keyboard,
  LayoutChangeEvent,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import Animated, {
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useStyles from './DiscoverScreen.style';
import ContentCard from './elements/ContentCard/ContentCard';
import DiscoverCreativeConnect from './elements/CreativeConnect/DiscoverCreativeConnect';
import Groups from './elements/Groups/Groups';
import SearchBox, { DiscoverSearchTab } from './elements/SearchBox/SearchBox';
import Alert from '/Alert';
import Button from '/components/Button';
import JobBoard from '/components/JobBoard/JobBoard';
import OrganizationMapMarker from '/components/Maps/OrganizationMapMarker';
import SearchBar from '/components/SearchBar/SearchBar';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionContainer from '/components/common/Section/SectionContainer';
import SectionTitle from '/components/common/Section/SectionTitle';
import SectionTitleIconContainer from '/components/common/Section/SectionTitleIconContainer';
import { withLastKnownPositionHOC } from '/components/withLastKnownPositionHOC';
import { BUTTON_LABEL_STYLE, KEY_GREEN, TEXT_INPUT } from '/constants';
import { useAuthContext } from '/context';
import {
  DiscoverMapFilterInput,
  DiscoverMapType,
  GetDiscoverMapQuery,
  JobPostFilter,
  MapBounds,
  UserRole,
  useGetDiscoverMapQuery,
} from '/generated/graphql';
import { isOrganizationProfileComplete } from '/util';
import useDebouncedState from '/hooks/useDebouncedState';
import DiscoverEvents from './elements/Events/DiscoverEvents';
import env from '../../env';

interface Props {
  lastKnownPosition: {
    latitude: number | undefined;
    longitude: number | undefined;
  } | null;
  navigation: StackNavigationProp<any>;
  route: RouteProp<any>;
}

const WEB_MAP_PROPS =
  Platform.OS === 'web'
    ? {
        options: {
          fullscreenControl: false,
          mapTypeControl: false,
        },
      }
    : undefined;

const COLLAPSED_CARD_HEIGHT = 240;

function DiscoverScreen(props: Props) {
  const locales = useLocales();

  const initialRegion = useMemo(() => {
    const regionCode = locales[0].regionCode;
    const getRegionByRegionCode = (code: string) => {
      switch (code) {
        case 'US':
        case 'CA':
        case 'MX':
        case 'BR':
        case 'AR':
        case 'CO':
        case 'PE':
        case 'VE':
        case 'CL':
          // If the region is somewhere in the Americas, center on the Americas at large
          return {
            latitude: 35.0902,
            longitude: -94.7129,
          };
        default:
          // If not, then center Eastern Africa
          return {
            latitude: 15.7832,
            longitude: 34.5085,
          };
      }
    };
    const defaultRegion = regionCode
      ? getRegionByRegionCode(regionCode)
      : undefined;

    return {
      latitude:
        Number(props.route.params?.lat) ||
        props.lastKnownPosition?.latitude ||
        defaultRegion?.latitude ||
        0,
      longitude:
        Number(props.route.params?.lng) ||
        props.lastKnownPosition?.longitude ||
        defaultRegion?.longitude ||
        0,
      latitudeDelta:
        Number(props.route.params?.d) || props.lastKnownPosition ? 50 : 90,
      longitudeDelta:
        !props.route.params?.d && props.lastKnownPosition ? 50 : 120,
    };
  }, [
    locales,
    props.lastKnownPosition,
    props.route.params?.d,
    props.route.params?.lat,
    props.route.params?.lng,
  ]);

  const { styles } = useStyles();

  const mapRef = useRef<MapView | null>();

  const scrollViewRef = useRef<any>(null);

  useScrollToTop(scrollViewRef);

  const { userData } = useAuthContext();

  const { height: windowHeight } = useWindowDimensions();

  const [headerHeight, setHeaderHeight] = useState(40);

  const [selectedBounds, _setSelectedBounds] = useState<
    MapBounds | undefined
  >();
  const setSelectedBounds = useCallback(
    (bounds: MapBounds | undefined) => {
      _setSelectedBounds(bounds);
      props.navigation.setParams({
        s: JSON.stringify(
          bounds &&
            ({
              northeast: {
                latitude: bounds?.northeast.latitude,
                longitude: bounds?.northeast.longitude,
              },
              southwest: {
                latitude: bounds?.southwest.latitude,
                longitude: bounds?.southwest.longitude,
              },
            } as Omit<MapBounds, '__typename'>),
        ),
      });
    },
    [props.navigation],
  );

  const bottomTabsHeight = useBottomTabBarHeight();
  const safeAreaInsets = useSafeAreaInsets();

  const mapHeight = windowHeight - headerHeight - bottomTabsHeight - 100;

  const [currentTab, setCurrentTab] = useState<DiscoverSearchTab>();

  const [searchQuery, searchQueryDebounced, setSearchQuery] =
    useDebouncedState('');

  const [jobBoardQuery, jobQueryDebounced, _setJobBoardQuery] =
    useDebouncedState(props.route.params?.jq || '');
  const setJobBoardQuery = useCallback(
    (q: string | undefined) => {
      _setJobBoardQuery(q);
      props.navigation.setParams({ jq: q });
    },
    [_setJobBoardQuery, props.navigation],
  );

  const [filter, _setFilter] = useState<Required<DiscoverMapFilterInput>>({
    jobs: {},
    groups: {},
  });
  const filterRef = useRef<Required<DiscoverMapFilterInput>>(filter);
  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);
  const setFilter = useCallback(
    function (data: Partial<DiscoverMapFilterInput>) {
      _setFilter({
        ...filterRef.current,
        ...data,
      });
    },
    [filterRef],
  );

  const onJobFilterChanged = useCallback(
    (jobFilter: JobPostFilter) => {
      setFilter({
        jobs: jobFilter,
      });
    },
    [setFilter],
  );

  /** This state variable will snap to a more coarse grid to reduce the number
   * of refreshes & requests sent to the server. */
  const [queryViewport, _setQueryViewport] = useState({
    ...initialRegion,
  });
  const _setQueryViewportDebounced = debounce(_setQueryViewport, 500);

  const mapType = (() => {
    switch (currentTab?.key) {
      // case 'jobs':
      //   return DiscoverMapType.Jobs;
      case 'groups':
        return DiscoverMapType.Groups;
      case 'organizations':
      default:
        return DiscoverMapType.Organizations;
    }
  })();

  const [{ data, fetching, error, stale }] = useGetDiscoverMapQuery({
    variables: {
      query: searchQueryDebounced,
      type: mapType,
      filter: {
        // jobs: currentTab?.key === 'jobs' ? filter.jobs : undefined,
      },
      viewport: {
        ...queryViewport,
        latitudeDelta: queryViewport.latitudeDelta * 1.2,
        longitudeDelta: queryViewport.longitudeDelta * 1.2,
      },
    },
    pause: currentTab?.mapEnabled === false,
    requestPolicy: 'cache-and-network',
  });

  const snapToGrid = useCallback(function (viewport: typeof queryViewport) {
    let snap = 1;

    // snap should be bigger if the delta is bigger
    const multiplier =
      viewport.latitudeDelta > 10 ? 2 : viewport.latitudeDelta > 1 ? 1 : 0.1;

    snap *= multiplier;

    const deltaSnap =
      viewport.latitudeDelta > 10 ? 1 : viewport.latitudeDelta > 1 ? 0.1 : 0.02;

    return {
      latitude: Math.round(viewport.latitude / snap) * snap,
      longitude: Math.round(viewport.longitude / snap) * snap,
      latitudeDelta: Math.max(
        Math.round(viewport.latitudeDelta / deltaSnap) * deltaSnap,
        0.1,
      ),
      longitudeDelta: Math.max(
        Math.round(viewport.longitudeDelta / deltaSnap) * deltaSnap,
        0.1,
      ),
    };
  }, []);

  const setQueryViewport = useCallback(
    function (newViewport: typeof queryViewport) {
      props.navigation.setParams({
        lat: newViewport.latitude,
        lng: newViewport.longitude,
        d: newViewport.latitudeDelta,
      });

      _setQueryViewportDebounced(snapToGrid(newViewport));
    },
    [_setQueryViewportDebounced, props.navigation, snapToGrid],
  );

  const totalResults = data?.getDiscoverMap.grid.reduce((acc, item) => {
    return (
      acc +
      (item?.reduce((_acc, _item) => {
        return _acc + (_item?.total_results || 0);
      }, 0) || 0)
    );
  }, 0);

  useEffect(() => {
    // Whenever we change tabs, clear the selected bounds
    setSelectedBounds(undefined);
  }, [currentTab, setSelectedBounds]);

  useEffect(() => {
    if (!props.route.params?.s) return;

    let selection;

    try {
      selection = JSON.parse(props.route.params.s);
    } catch (err) {
      // Clear invalid query parameter
      props.navigation.setParams({ s: undefined });
    }

    if (selection) {
      _setSelectedBounds(selection);
    }
  }, [props.navigation, props.route.params?.s]);

  const hasInitialized = useRef(false);
  useEffect(() => {
    if (
      !hasInitialized.current &&
      typeof props.lastKnownPosition?.latitude === 'number' &&
      typeof props.lastKnownPosition.longitude === 'number'
    ) {
      hasInitialized.current = true;
      mapRef.current?.animateCamera({
        center: {
          latitude: props.lastKnownPosition.latitude,
          longitude: props.lastKnownPosition.longitude,
        },
        zoom: 4,
      });
    }
  }, [props.lastKnownPosition]);

  useEffect(() => {
    if (error)
      Alert.alert('Oh no!', 'Something went wrong while fetching map data');
  }, [error]);

  function onHeaderLayout(event: LayoutChangeEvent) {
    const { height } = event.nativeEvent.layout;
    setHeaderHeight(height);
  }

  function onSelectResult(
    cell: NonNullable<
      NonNullable<GetDiscoverMapQuery['getDiscoverMap']['grid']>[0]
    >[0],
  ) {
    if (cell?.total_results === 1) {
      switch (mapType) {
        case DiscoverMapType.Organizations:
          props.navigation.navigate('Profile', {
            id: cell?.top_result?.user?.id,
          });
          return;
        case DiscoverMapType.Groups:
          props.navigation.navigate('ViewGroup', {
            id: cell?.top_result?.group?.id,
          });
          return;
      }
    }
    setSelectedBounds(cell?.bounds);
  }

  function onCloseContentCard() {
    setSelectedBounds(undefined);
  }

  const mapDisabledOverlayOpacity = useSharedValue(0);

  // Opacity should animate to 1 if the map is disabled, and 0 if it's enabled
  useAnimatedReaction(
    () => {
      return mapDisabledOverlayOpacity.value;
    },
    () => {
      if (currentTab?.mapEnabled === false) {
        mapDisabledOverlayOpacity.value = withTiming(1, {
          duration: 200,
        });
      } else {
        mapDisabledOverlayOpacity.value = withTiming(0, {
          duration: 200,
        });
      }
    },
    [currentTab?.mapEnabled],
  );

  const mapDisabledOverlayStyle = useAnimatedStyle(() => {
    return {
      opacity: mapDisabledOverlayOpacity.value,
    };
  });

  return (
    <KeyboardAwareScrollView
      style={{ flex: 1 }}
      innerRef={(r) => {
        if (r) {
          scrollViewRef.current = r;
        }
      }}
      contentContainerStyle={{
        paddingBottom: 10,
      }}
    >
      <View style={{ height: mapHeight, overflow: 'hidden' }}>
        <View
          style={[
            styles('screenHeaderContainer'),
            {
              top: safeAreaInsets.top + 16,
              overflow: 'hidden',
            },
          ]}
          onLayout={onHeaderLayout}
          pointerEvents="box-none"
        >
          <SearchBox
            onQueryChanged={setSearchQuery}
            onTabChange={setCurrentTab}
          />
        </View>
        <MapView
          {...WEB_MAP_PROPS}
          provider={PROVIDER_GOOGLE}
          ref={(r) => {
            if (r) {
              mapRef.current = r;
            }
          }}
          style={{
            flex: 1,
          }}
          initialRegion={initialRegion}
          onTouchStart={() => {
            Keyboard.dismiss();
          }}
          // @ts-ignore - For Web
          options={{
            minZoom: 3,
            fitBounds: {
              north: 90,
              south: -90,
              west: -180,
              east: 180,
            },
          }}
          onRegionChangeComplete={(newRegion) => {
            if (_.isEqual(snapToGrid(newRegion), queryViewport)) return;

            setQueryViewport(newRegion);
          }}
          minZoomLevel={1}
        >
          {props.lastKnownPosition?.latitude &&
          props.lastKnownPosition.longitude ? (
            <Marker
              pinColor="#00FF9D"
              coordinate={{
                latitude: props.lastKnownPosition.latitude,
                longitude: props.lastKnownPosition.longitude,
              }}
              style={{
                zIndex: 1,
                width: 32,
                height: 32,
              }}
            >
              <View
                style={{
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  borderRadius: 100,
                }}
              >
                <View
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    backgroundColor: KEY_GREEN,
                  }}
                />
              </View>
            </Marker>
          ) : null}

          {data?.getDiscoverMap.grid
            .flat()
            .filter((i) => !!i)
            .map((item) => {
              if (!item) return null;

              const key = JSON.stringify(item.bounds);

              let thumbnail = item.top_result?.user?.profile_image;

              switch (mapType) {
                // case DiscoverMapType.Jobs:
                //   if (item.top_result?.job_post?.user?.profile_image) {
                //     thumbnail = item.top_result?.job_post?.user?.profile_image;
                //   }
                //   break;
                case DiscoverMapType.Groups:
                  if (item.top_result?.group?.group_logo) {
                    thumbnail = item.top_result?.group?.group_logo;
                  }
              }

              return (
                <Marker
                  key={key}
                  style={{
                    zIndex: item.total_results,
                  }}
                  onPress={() => onSelectResult(item)}
                  coordinate={item.center}
                  anchor={{
                    x: 0.5,
                    y: 0.5,
                  }}
                >
                  <OrganizationMapMarker
                    thumbnail={thumbnail || undefined}
                    onPress={() => onSelectResult(item)}
                    badge_number={item.total_results}
                  />
                </Marker>
              );
            })}
        </MapView>
        <Animated.View
          style={styles('overlayContainer')}
          pointerEvents="box-none"
        >
          <Animated.View
            style={[
              {
                ...StyleSheet.absoluteFillObject,
                backgroundColor: 'rgba(10,10,10,0.8)',
                alignItems: 'center',
                justifyContent: 'center',
              },
              mapDisabledOverlayStyle,
            ]}
            pointerEvents={currentTab?.mapEnabled === false ? 'auto' : 'none'}
          >
            <Text style={styles('mapDisabledText')}>
              No map results for selected category
            </Text>
          </Animated.View>

          {/* CONTENT CARD */}
          <View
            style={styles('overlayInnerContainer')}
            pointerEvents={'box-none'}
          >
            <ContentCard
              forceCollapse={false}
              query={searchQuery}
              currentTab={currentTab}
              onFilterChanged={setFilter}
              headerHeight={headerHeight + safeAreaInsets.top}
              totalResults={totalResults}
              loading={fetching || stale}
              onSearchQueryChange={(q) => setSearchQuery(q || '')}
              onClose={onCloseContentCard}
              type="organizations"
              bounds={selectedBounds}
              collapsedCardHeight={COLLAPSED_CARD_HEIGHT}
            />
          </View>
        </Animated.View>
      </View>

      <View>
        <SectionContainer>
          <HorizontalContainer
            style={{
              marginBottom: 6,
            }}
          >
            <SectionTitleIconContainer>
              {/* Job board */}
              <FontAwesome5 name="suitcase" size={24} color="black" />
            </SectionTitleIconContainer>
            <SectionTitle
              style={{
                marginBottom: 0,
              }}
            >
              JOB BOARD
            </SectionTitle>
          </HorizontalContainer>

          <SearchBar
            loading={fetching}
            value={jobBoardQuery}
            onChangeText={setJobBoardQuery}
            containerStyle={{
              ...TEXT_INPUT,
              height: 'auto',
              minHeight: 36,
              marginBottom: 8,
              marginHorizontal: 0,
            }}
          />

          <JobBoard
            query={jobQueryDebounced}
            onFilterChanged={onJobFilterChanged}
            disablePagination
          />
        </SectionContainer>

        <DiscoverEvents />

        <SectionContainer>
          <HorizontalContainer
            style={{
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: 6,
            }}
          >
            <HorizontalContainer style={{ flex: 1 }}>
              <SectionTitleIconContainer>
                {/* Groups */}
                <FontAwesome5 name="users" size={24} color="black" />
              </SectionTitleIconContainer>
              <SectionTitle
                style={{
                  marginBottom: 0,
                }}
              >
                GROUPS
              </SectionTitle>
            </HorizontalContainer>

            {userData?.role === UserRole.Supporter ||
            (userData?.role === UserRole.Conservationist &&
              isOrganizationProfileComplete(userData)) ? (
              <Button
                onPress={() => {
                  props.navigation.navigate('CreateGroup');
                }}
                label={
                  <HorizontalContainer>
                    <AntDesign name="pluscircleo" size={15} color="black" />
                    <Text style={BUTTON_LABEL_STYLE}>{` `}Create Group</Text>
                  </HorizontalContainer>
                }
                containerStyle={{
                  alignSelf: 'flex-end',
                }}
              />
            ) : null}
          </HorizontalContainer>

          <Groups />
        </SectionContainer>

        {env.ENV_NAME !== 'development' ? null : <DiscoverCreativeConnect />}
      </View>
    </KeyboardAwareScrollView>
  );
}

export default withLastKnownPositionHOC(DiscoverScreen);
