/** Takes a lazily imported screen and returns a component that wraps it in React.Suspense */
function createScreen(
  Component: React.LazyExoticComponent<React.ComponentType<any>>,
) {
  return (props: any) => (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <Component {...props} />
    </React.Suspense>
  );
}

const AccountSettingsScreen = createScreen(
  React.lazy(() => import('/screens/AccountSettingsScreen')),
);
const CampaignStatsScreen = createScreen(
  React.lazy(() => import('/screens/CampaignStatsScreen')),
);
const CloseCampaignScreen = createScreen(
  React.lazy(() => import('/screens/CloseCampaignScreen/CloseCampaignScreen')),
);
const ConnectionsScreen = createScreen(
  React.lazy(() => import('/screens/Connections/ConnectionScreen')),
);
const CreateCampaignUpdateScreen = createScreen(
  React.lazy(
    () => import('/screens/CreateCampaignUpdate/CreateCampaignUpdateScreen'),
  ),
);
const CreateReportScreen = createScreen(
  React.lazy(() => import('/screens/CreateReportScreen')),
);
const EditCampaignScreen = createScreen(
  React.lazy(
    () => import('../../../screens/EditCampaignScreen/EditCampaignScreen'),
  ),
);
const ListCampaignsScreen = createScreen(
  React.lazy(() => import('/screens/ListCampaignsScreen')),
);
const ManageCampaignScreen = createScreen(
  React.lazy(() => import('/screens/ManageCampaignScreen')),
);
const ProfileScreen = createScreen(
  React.lazy(() => import('/screens/ProfileScreen')),
);
const ViewDiscussionBoard = createScreen(
  React.lazy(() => import('/screens/ViewDiscussionBoard/ViewDiscussionBoard')),
);
const ViewApplicationDetails = createScreen(
  React.lazy(() => import('/screens/MyImpact/ViewApplicationDetails')),
);
const GiveSkillsScreen = createScreen(
  React.lazy(() => import('/screens/TakeAction/GiveSkillsScreen')),
);
const VolunteerScreen = createScreen(
  React.lazy(() => import('/screens/TakeAction/VolunteerScreen')),
);
const ViewCampaignScreen = createScreen(
  React.lazy(() => import('/screens/ViewCampaignScreen')),
);
const ViewCommentsScreen = createScreen(
  React.lazy(() => import('/screens/ViewCommentsScreen')),
);
const CreateSkillRequestApplicationScreen = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/CreateSkillRequestApplicationScreen/CreateSkillRequestApplicationScreen'
      ),
  ),
);
const EditProfileLocations = createScreen(
  React.lazy(
    () => import('/screens/EditProfileLocations/EditProfileLocations'),
  ),
);
const ListCampaignsWithStats = createScreen(
  React.lazy(
    () => import('/screens/ListCampaignWithStats/ListCampaignsWithStats'),
  ),
);
const ListUsersScreen = createScreen(
  React.lazy(() => import('/screens/ListUsersScreen')),
);
const ListVolunteers = createScreen(
  React.lazy(() => import('/screens/ManageVolunteerRequest/ListVolunteers')),
);
const ManageVolunteerRequest = createScreen(
  React.lazy(
    () => import('/screens/ManageVolunteerRequest/ManageVolunteerRequest'),
  ),
);
const MyImpactCampaignsScreen = createScreen(
  React.lazy(
    () => import('/screens/MyImpactCampaigns/MyImpactCampaignsScreen'),
  ),
);
const ManageSkillRequestScreen = createScreen(
  React.lazy(() => import('/screens/OurImpact/ManageSkillRequestScreen')),
);
const ViewApplicantDetailsScreen = createScreen(
  React.lazy(() => import('/screens/OurImpact/ViewApplicantDetailsScreen')),
);
const OrganizationStatsScreen = createScreen(
  React.lazy(
    () => import('/screens/OrganizationStatsScreen/OrganizationStatsScreen'),
  ),
);
const ResearchTopicSummaryScreen = createScreen(
  React.lazy(
    () =>
      import('/screens/ResearchTopicSummaryScreen/ResearchTopicSummaryScreen'),
  ),
);
const SkillSummaryScreen = createScreen(
  React.lazy(() => import('/screens/SkillSummaryScreen')),
);
const SpeciesSummaryScreen = createScreen(
  React.lazy(
    () => import('/screens/SpeciesSummaryScreen/SpeciesSummaryScreen'),
  ),
);
const EditTeamMemberScreen = createScreen(
  React.lazy(() => import('/screens/Teams/EditTeamMemberScreen')),
);
const InviteTeamMemberScreen = createScreen(
  React.lazy(() => import('/screens/Teams/InviteTeamMemberScreen')),
);
const ManageTeamScreen = createScreen(
  React.lazy(() => import('/screens/Teams/ManageTeamScreen')),
);
const ViewMyTeamsScreen = createScreen(
  React.lazy(() => import('/screens/Teams/ViewMyTeamsScreen')),
);
const ViewPendingTeamMemberships = createScreen(
  React.lazy(() => import('/screens/Teams/ViewPendingTeamMemberships')),
);
const ViewPartnershipsScreen = createScreen(
  React.lazy(() => import('/screens/ViewPartnershipsScreen')),
);
const VolunteerSignUpScreen = createScreen(
  React.lazy(
    () => import('/screens/VolunteerSignUpScreen/VolunteerSignUpScreen'),
  ),
);
const SpeciesVernacularNames = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/SpeciesSummaryScreen/SpeciesVernacularNames/SpeciesVernacularNames'
      ),
  ),
);
const SpeciesScientificNames = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/SpeciesSummaryScreen/SpeciesScientificNames/SpeciesScientificNames'
      ),
  ),
);
const ChangePassword = createScreen(
  React.lazy(() => import('/screens/ChangePassword/ChangePassword')),
);
const CreateMomentInNature = createScreen(
  React.lazy(
    () => import('/screens/CreateMomentInNature/CreateMomentInNature'),
  ),
);
import React from 'react';
// const ViewMomentInNature = createScreen(
//   React.lazy(() => import('/screens/ViewMomentInNature/ViewMomentInNature')),
// );
import ViewMomentInNature from '/screens/ViewMomentInNature/ViewMomentInNature';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
const EditMomentInNature = createScreen(
  React.lazy(() => import('/screens/EditMomentInNature/EditMomentInNature')),
);
const ChangeEmail = createScreen(
  React.lazy(() => import('/screens/ChangeEmail/ChangeEmail')),
);
const DonationSettings = createScreen(
  React.lazy(() => import('/screens/DonationSettings/DonationSettings')),
);
const StripePayouts = createScreen(
  React.lazy(() => import('/screens/StripePayouts/StripePayouts')),
);
const DonateScreen = createScreen(
  React.lazy(() => import('/screens/TakeAction/DonateScreen')),
);
const DonateSuccess = createScreen(
  React.lazy(() => import('/screens/DonateSuccess/DonateSuccess')),
);
const ListCampaignDonations = createScreen(
  React.lazy(
    () => import('/screens/ListCampaignDonations/ListCampaignDonations'),
  ),
);
const ListMyDonations = createScreen(
  React.lazy(() => import('/screens/ListMyDonations/ListMyDonations')),
);
const CreateJobPost = createScreen(
  React.lazy(() => import('/screens/CreateJobPost/CreateJobPost')),
);
const EditJobPost = createScreen(
  React.lazy(() => import('/screens/EditJobPost/EditJobPost')),
);
const ViewJobPost = createScreen(
  React.lazy(() => import('/screens/ViewJobPost/ViewJobPost')),
);
const ListJobPosts = createScreen(
  React.lazy(() => import('/screens/ListJobPosts/ListJobPosts')),
);
const BookmarksScreen = createScreen(
  React.lazy(() => import('/screens/BookmarksScreen/BookmarksScreen')),
);
const ViewKeyBulletinPost = createScreen(
  React.lazy(() => import('/screens/ViewKeyBulletinPost/ViewKeyBulletinPost')),
);
const ListKeyBulletinPosts = createScreen(
  React.lazy(
    () => import('/screens/ListKeyBulletinPosts/ListKeyBulletinPosts'),
  ),
);
const CreateKeyBulletinPost = createScreen(
  React.lazy(
    () => import('/screens/CreateKeyBulletinPost/CreateKeyBulletinPost'),
  ),
);
const EditKeyBulletinPost = createScreen(
  React.lazy(() => import('/screens/EditKeyBulletinPost/EditKeyBulletinPost')),
);
const NotificationPreferences = createScreen(
  React.lazy(
    () => import('/screens/NotificationPreferences/NotificationPreferences'),
  ),
);
const ListDirectConversations = createScreen(
  React.lazy(
    () => import('/screens/ListDirectConversations/ListDirectConversations'),
  ),
);
const DirectMessageScreen = createScreen(
  React.lazy(() => import('/screens/DirectMessageScreen/DirectMessageScreen')),
);
const ListBlockedUsers = createScreen(
  React.lazy(() => import('/screens/ListBlockedUsers/ListBlockedUsers')),
);
const CreateGroup = createScreen(
  React.lazy(() => import('/screens/CreateGroup/CreateGroup')),
);
const ViewGroup = createScreen(
  React.lazy(() => import('/screens/ViewGroup/ViewGroup')),
);
const GroupMembers = createScreen(
  React.lazy(() => import('/screens/ListGroupMembers/ListGroupMembers')),
);
const ManageGroup = createScreen(
  React.lazy(() => import('/screens/ManageGroup/ManageGroup')),
);
const EditGroupDiscussionCategories = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/EditGroupDiscussionCategories/EditGroupDiscussionCategories'
      ),
  ),
);
const ViewGroupDiscussionPost = createScreen(
  React.lazy(
    () => import('/screens/ViewGroupDiscussionPost/ViewGroupDiscussionPost'),
  ),
);
const EditGroup = createScreen(
  React.lazy(() => import('/screens/EditGroup/EditGroup')),
);
const CreateNewsPost = createScreen(
  React.lazy(() => import('/screens/CreateNewsPost/CreateNewsPost')),
);
const EditNewsPost = createScreen(
  React.lazy(() => import('/screens/EditNewsPost/EditNewsPost')),
);
const ViewNewsPost = createScreen(
  React.lazy(() => import('/screens/ViewNewsPost/ViewNewsPost')),
);
const ViewSpeciesUserContent = createScreen(
  React.lazy(
    () => import('/screens/ViewSpeciesUserContent/ViewSpeciesUserContent'),
  ),
);
const JobBoardScreen = createScreen(
  React.lazy(() => import('/screens/JobBoardScreen/JobBoardScreen')),
);
const ManageDonationRequest = createScreen(
  React.lazy(
    () => import('/screens/ManageDonationRequest/ManageDonationRequest'),
  ),
);
const ViewSharedContentBucket = createScreen(
  React.lazy(
    () => import('/screens/ViewSharedContentBucket/ViewSharedContentBucket'),
  ),
);
const ListUserGroups = createScreen(
  React.lazy(() => import('/screens/Groups/ListUserGroups/ListUserGroups')),
);
const SearchGroups = createScreen(
  React.lazy(() => import('/screens/Groups/SearchGroups/SearchGroups')),
);
const DeleteAccount = createScreen(
  React.lazy(() => import('/screens/DeleteAccount/DeleteAccount')),
);
const CreateCreativeConnectProject = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/CreativeConnect/CreateCreativeConnectProject/CreateCreativeConnectProject'
      ),
  ),
);
const EditCreativeConnectProject = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/CreativeConnect/EditCreativeConnectProject/EditCreativeConnectProject'
      ),
  ),
);
const ApplyToCreativeConnectProject = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/CreativeConnect/ApplyToCreativeConnectProject/ApplyToCreativeConnectProject'
      ),
  ),
);
const ListCreativeConnectCollaborators = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/CreativeConnect/ListCreativeConnectCollaborators/ListCreativeConnectCollaborators'
      ),
  ),
);
const ListCreativeConnectProjects = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/CreativeConnect/ListCreativeConnectProjects/ListCreativeConnectProjects'
      ),
  ),
);
const CreativeConnectLandingPage = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/CreativeConnect/CreativeConnectLandingPage/CreativeConnectLandingPage'
      ),
  ),
);
const CreativeConnectDashboard = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/CreativeConnect/CreativeConnectDashboard/CreativeConnectDashboard'
      ),
  ),
);
const ListNewsPosts = createScreen(
  React.lazy(() => import('/screens/ListNewsPosts/ListNewsPosts')),
);
const ListSurveySubmissionsScreen = createScreen(
  React.lazy(
    () => import('/screens/ListSurveySubmissions/ListSurveySubmissions'),
  ),
);
const CreateEventScreen = createScreen(
  React.lazy(
    () => import('/screens/Events/CreateEventScreen/CreateEventScreen'),
  ),
);
const EditEventScreen = createScreen(
  React.lazy(() => import('/screens/Events/EditEventScreen/EditEventScreen')),
);
const ListEventsScreen = createScreen(
  React.lazy(() => import('/screens/Events/ListEventsScreen/ListEventsScreen')),
);
const ViewEventScreen = createScreen(
  React.lazy(() => import('/screens/Events/ViewEventScreen/ViewEventScreen')),
);
const ListEventAttendeesScreen = createScreen(
  React.lazy(
    () =>
      import(
        '/screens/Events/ListEventAttendeesScreen/ListEventAttendeesScreen'
      ),
  ),
);
const ViewEventInvitation = createScreen(
  React.lazy(
    () => import('/screens/Events/ViewEventInvitation/ViewEventInvitation'),
  ),
);
const ListMyEventsScreen = createScreen(
  React.lazy(
    () => import('/screens/Events/ListMyEventsScreen/ListMyEventsScreen'),
  ),
);

const Screens = {
  AccountSettingsScreen,
  CampaignStatsScreen,
  CloseCampaignScreen,
  ConnectionsScreen,
  CreateCampaignUpdateScreen,
  CreateReportScreen,
  EditCampaignScreen,
  ListCampaignsScreen,
  ManageCampaignScreen,
  ProfileScreen,
  ViewDiscussionBoard,
  ViewApplicationDetails,
  GiveSkillsScreen,
  VolunteerScreen,
  ViewCampaignScreen,
  ViewCommentsScreen,
  CreateSkillRequestApplicationScreen,
  EditProfileLocations,
  ListCampaignsWithStats,
  ListUsersScreen,
  ListVolunteers,
  ManageVolunteerRequest,
  MyImpactCampaignsScreen,
  ManageSkillRequestScreen,
  ViewApplicantDetailsScreen,
  OrganizationStatsScreen,
  ResearchTopicSummaryScreen,
  SkillSummaryScreen,
  SpeciesSummaryScreen,
  EditTeamMemberScreen,
  InviteTeamMemberScreen,
  ManageTeamScreen,
  ViewMyTeamsScreen,
  ViewPendingTeamMemberships,
  ViewPartnershipsScreen,
  VolunteerSignUpScreen,
  SpeciesVernacularNames,
  SpeciesScientificNames,
  ChangePassword,
  CreateMomentInNature,
  ViewMomentInNature,
  EditMomentInNature,
  ChangeEmail,
  DonationSettings,
  StripePayouts,
  DonateScreen,
  DonateSuccess,
  ListCampaignDonations,
  ListMyDonations,
  CreateJobPost,
  EditJobPost,
  ViewJobPost,
  ListJobPosts,
  BookmarksScreen,
  ViewKeyBulletinPost,
  ListKeyBulletinPosts,
  CreateKeyBulletinPost,
  EditKeyBulletinPost,
  NotificationPreferences,
  ListDirectConversations,
  DirectMessageScreen,
  ListBlockedUsers,
  CreateGroup,
  ViewGroup,
  GroupMembers,
  ManageGroup,
  EditGroupDiscussionCategories,
  ViewGroupDiscussionPost,
  EditGroup,
  CreateNewsPost,
  EditNewsPost,
  ViewNewsPost,
  ViewSpeciesUserContent,
  JobBoardScreen,
  ManageDonationRequest,
  ViewSharedContentBucket,
  ListUserGroups,
  SearchGroups,
  DeleteAccount,
  CreateCreativeConnectProject,
  EditCreativeConnectProject,
  ApplyToCreativeConnectProject,
  ListCreativeConnectCollaborators,
  ListCreativeConnectProjects,
  CreativeConnectLandingPage,
  CreativeConnectDashboard,
  ListNewsPosts,
  ListSurveySubmissionsScreen,
  CreateEventScreen,
  EditEventScreen,
  ListEventsScreen,
  ViewEventScreen,
  ListEventAttendeesScreen,
  ViewEventInvitation,
  ListMyEventsScreen,
};

export default Screens;
